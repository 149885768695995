.bg_1_image {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 85vh;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.home-page-root {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-width: 960px;
  text-align: left;
  font-size: var(--font-size-19xl);
  color: var(--color-black);
  font-family: var(--font-poppins);
  padding: 3vh 2vw 2vh 2vw;

  .homepage-header {
    text-align: center;
    font-size: 1.5vmax;
    font-weight: 600;
  }
  .goals-header {
    text-align: center;
    font-size: 1vmax;
    font-weight: 600;
  }

  .home-checklist-check {
    padding: 0;
  }

  .home-checklist-text {
    font-family: var(--font-poppins);
    font-size: 0.85vmax;
    font-weight: 500;
  }

  .home-checklist-text-sub {
    font-family: var(--font-poppins);
    font-size: 0.65vmax;
    font-style: italic;
  }

  .button-div {
    cursor: pointer;
    display: flex;
  }

  .checklist-sub-header {
    text-align: center;
    font-family: var(--font-poppins);
    color: #0146C6;
    font-size: 1.5vmax;
    font-weight: 400;
  }

  .checklist-group {
    width: 100%;
    height: 100%;
    max-height: 65vh;
    flex-wrap: nowrap;
    overflow-y: auto; 
    overflow-x: hidden;
  }
  
  .checklist-group::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  .checklist-group::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color:#000 ;
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }

  .checklist-item {
    width: 100%;
    padding: 0 10%;
    display: flex;
    align-items: flex-start;
  }

  .checklist-checkbox {
    padding: 0;
    padding-right: 1px;
  }

  .checklist-text {
    font-family: var(--font-poppins);
    font-size: 0.9vmax;  
    font-weight: 400;
  }

  .studyplan-design-top {
    height: 100%;
    width: 100%;
    border: 5px solid #0145C3;
    // border-image: linear-gradient(180deg, #0145C3 0%, #06307F 100%);
    // border-image-slice: 1;
    box-shadow: 0 0 10px rgba(1, 69, 195, 0.8);
    border-radius: 1vmax;
    margin-bottom: 1%;
  }

  .course-list-box {
    height: 56vh;
    width: 100%;
    border: 2px solid #06307F;
    box-shadow: 0 0 10px rgba(1, 69, 195, 0.8);
    border-radius: 1vmax;
    margin: 2% 10%;
    padding: 2%;
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: auto; 
  }
  
  .course-list-box::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  .course-list-box::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color:#000 ;
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }

  .course-header {
    font-family: var(--font-poppins);
    font-size: 1.15vmax;  
  }

  .course-check-item {
    width: 80%;
    font-family: var(--font-poppins) !important;
    font-size: 0.85vmax !important;  
  }

  .course-check-item span {
    font-family: var(--font-poppins) !important;
    font-size: 0.85vmax !important;  
  }

  .college_link_bottom {
    width: 100%;
    height: 100%;
    max-height: 65vh;
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: auto; 
  }
  
  .college_link_bottom::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  .college_link_bottom::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color:#000 ;
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
  
  .college_link_header {
    text-align: center;
    text-transform: uppercase;
    font-family: var(--font-poppins);
    font-weight: 400;
    font-size: 2vmax !important;
    letter-spacing: 0.25vmax !important;
    margin-top: -1.5vh
  }

 .site_licensing {
   font-family: var(--font-poppins);
   margin-top: 1vh !important;
 }

  .college_link_sub_header {
    text-align: center;
    font-family: var(--font-poppins);
    color: #0146C6;
    font-size: 1.25vmax;
    font-weight: 400;
  }
  
  .college_link_icon {
    font-family: var(--font-poppins);
    font-weight: 400;
    color: #001D73 !important;
    height: 1vmax !important;
    width: 1vmax !important;
  }
  
  .college_link_link {
    font-family: var(--font-poppins);
    font-weight: 400;
    color: #001D73 !important;
    font-size: 1vmax !important;
    text-decoration: none !important;
  }

  .college_link_text {
    font-family: var(--font-poppins);
    font-size: 1vmax;  
    font-weight: 400;
  }

  .ps_goal_bottom {
    width: 100%;
    height: 100%;
    max-height: 68vh;
    padding: 5px;
    gap: 5px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: auto; 
  }
  
  .ps_goal_bottom::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  .ps_goal_bottom::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color:#000 ;
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }

  .activity_check_item {
    padding: 1%;
    padding-left: 10%;
  }

  .activity_radio_item {
    padding-left: 10%;
  }

  .desmos_sub_header {
    font-family: var(--font-poppins);
    font-size: 1.75vmax;
  }

  .desmos_preview_image_small {
    height: 12vh;
    animation: slideInOut 1s ease-in-out; 
  }

  .desmos_preview_header_small {
    text-align: left;
    font-family: var(--font-poppins);
    font-weight: 400;
    font-size: 1vmax !important;
    width: 15vw;
  }

  .desmos_preview_video {
    height: 22vh;
    width: 22vw;
    animation: slideInOut 1s ease-in-out; 
  }

  .desmos_preview_header {
    text-align: left;
    font-family: var(--font-poppins);
    font-weight: 400;
    font-size: 1.5vmax !important;
    width: 20vw;
  }

  .desmos_preview_sub_header {
    text-align: left;
    font-family: var(--font-poppins);
    color: #0146C6;
    font-size: 0.9vmax;
    font-weight: 400;
  }

  .desmos_preview_image_1 {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .desmos_preview_image_2 {
    width: 90%;
    height: 90%;
    object-fit: cover;
  }

  .desmos_preview_image_3 {
    width: 90%;
    height: 75%;
    object-fit: cover;
  }

  .desmos_cards_active {
    border: 1px solid #043897;
    border-radius: 1vmax !important;
    background: linear-gradient(180deg, #1A58CC 0%, #3068D1 100%);
    font-family: var(--font-poppins);
    height: 35vh;
  }
  
  .desmos_cards_inactive {
    border: 1px solid #555555;
    border-radius: 1vmax !important;
    background: linear-gradient(180deg, #7F7F7F 0%, #555555 100%);
    font-family: var(--font-poppins);
    height: 35vh;
  }
  
  .desmos_cards_main {
    border: 1px solid #E5E5E5;
    border-radius: 1vmax !important;
    background: linear-gradient(180deg, #FFFFFF 0%, #E5E5E5 100%);
    font-family: var(--font-poppins);
    height: 75vh;
    margin-top: -5vh;
  }
  
  .desmos_cards_txt {
    font-weight: bold;
    font-size: 2vmax !important;
    color: #FFFF;
  }

  .desmos_buttons_txt {
    font-weight: bold;
    font-size: 2vmax !important;
    color: #FFFF;
    padding: 2vh;
    margin-bottom: 2vh;
    background-color: goldenrod;
    border-radius: 10px;
  }
  
  .desmos_cards_header {
    font-weight: bold;
    font-size: 1.5vmax !important;
    color: #043897;
    margin-top: 2vh !important;
  }

  .desmos_cards_li_txt {
    font-size: 1.15vmax !important;
    color: var(--color-black);
    font-family: var(--font-poppins);
  }
  
  .desmos_details_header {
    font-weight: bold;
    color: #043897;
    font-size: 2.5vmax !important;
    text-decoration: underline;
    font-family: var(--font-poppins);
  }
  
  .desmos_details_txt {
    font-size: 1.5vmax !important;
    color: var(--color-black);
    font-family: var(--font-poppins);
  }
  
  .desmos_details_txt li {
    margin-bottom: 2vh;
  }

  .desmos_non_main_coming_soon {
    position: absolute;
    align-self: flex-start;
    height: 5.5vh;
    margin-left: -10px;
    margin-top: -2px;
  }

}

.carousel-root {
  width: 100%;
}

.app-dashboard {
  display: flex;
  flex-direction: row;
  // align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-height: 100vh;
  // height: 100vh;
  position: relative;

  .home-page {
    position: relative;
    background-color: var(--color-white);
    width: 100%;
    height: 100%;
    // overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-width: 960px;
    text-align: left;
    font-size: var(--font-size-19xl);
    color: var(--color-black);
    font-family: var(--font-poppins);
  }

  .home-page-right {
    flex: 1;
    height: 100%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 2vmax 0px 0 2vmax;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-31xl);
    min-width: 220px;
    max-width: 310px;
    text-align: center;
    font-size: 14px;
    color: var(--color-white);
    margin-bottom: 10%;
  }

  .student-profile {
    position: relative;
    background-color: var(--color-white);
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-width: 960px;
    // max-width: 1920px;
    text-align: left;
    font-size: var(--font-size-19xl);
    color: var(--color-black);
    font-family: var(--font-poppins);
  }

  .logo-icon {
    position: relative;
    width: 80px;
    height: 80px;
    object-fit: cover;
  }

  .profilephoto-child {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    background-color: var(--color-white);
    width: 73px;
    height: 73px;
  }

  .div {
    position: absolute;
    top: calc(50% - 27.5px);
    left: calc(50% - 19.5px);
    display: flex;
    align-items: center;
    width: 35.24px;
    height: 40.28px;
    color: var(--color-black);

  }

  .profilephoto {
    width: 73px;
    height: 233px;
    font-size: 40px;
  }

  .house-user,
  .profilephoto,
  .setting {
    position: relative;
  }

  .nav-item-selected {
    border-radius: var(--br-6xl) 0 0 var(--br-6xl);
    background-color: var(--color-white);
    color: var(--color-royalblue-100) !important;
    width: 118px !important;
    justify-content: center !important;
    flex-direction: row;
    padding: 7px 0;
    box-sizing: border-box;
    justify-content: center;
    height: 144px;
  }

  .nav-link {
    text-decoration-line: none;
  }

  .house-user-wrapper,
  .setting-wrapper {
    width: 45.33px;
    overflow: hidden;
    height: 126px;
    color: var(--color-white);
  }


  .setting-wrapper,
  .house-user-wrapper,
  .logo-parent {
    display: flex;
    align-items: center;
  }

  .logo-parent {
    flex-direction: row;
    justify-content: flex-start;
    gap: 47px;
  }

  .frame-wrapper,
  .student-profile-inner {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .frame-wrapper {
    align-self: stretch;
    background-color: var(--color-royalblue-200);
    overflow: hidden;
    align-items: center;
    justify-content: flex-start;
  }

  .student-profile-inner {
    flex-basis: 120px;
    // height: 100%;
    // min-height: 100vh;
    font-size: var(--font-size-19xl);
    color: var(--color-black);
    align-items: flex-start;
    justify-content: center;
    min-width: 80px;
    max-width: 120px;
    font-family: var(--font-font-awesome-6-duotone);
  }

  .hello-john-txt-container {
    line-break: anywhere;
    width: 100%;
  }

  .line {
    display: flex;
    justify-content: space-between;
  }

  // .hello-john-txt-container p {
  //   flex-basis: 48%;
  // }

  .checkboxContainer {
    flex-basis: 50%;
  }

  // .good-morning,
  // .hello-john {
  //   position: relative;
  //   display: flex;
  //   align-items: center;
  //   width: 100%;
  //   height: 54px;
  //   flex-shrink: 0;
  // }

  // .good-morning {
  //   font-size: var(--font-size-base);
  //   font-weight: 300;
  //   height: 17px;
  // }

  // .hello-john-parent {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: flex-start;
  //   justify-content: flex-start;
  // }

  .enrolled-courses {
    position: relative;
    font-size: 1.5vmax;
    font-weight: 600;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;
  }

  .enrolled-courses:hover {
    color: #0073ec;
  }

  .frame-group {
    margin-top: 40px;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 26px;
  }

  .lorem-ipsum-dolor {
    align-self: stretch;
    position: relative;
    font-weight: 300;
  }

  .image-30-icon {
    max-width: 100%;
    overflow: hidden;
    object-fit: cover;
    height: 4vmax;
  }

  .image-30-icon,
  .take-diagnostics {
    flex: 1;
    position: relative;
  }

  .take-diagnostics-button-text {
    text-align: center;
    color: var(--color-white);
    font-size: var(--font-size-btn);
  }

  .take-diagnostics-wrapper {
    border-radius: var(--br-11xl);
    background-color: var(--color-royalblue-200);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 2vmax;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: 0 var(--padding-2xs);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }

  .take-diagnostics-wrapper-diabled {
    border-radius: var(--br-11xl);
    background-color: var(--color-royalblue-200);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    filter: grayscale(100%);
    opacity: 0.5;
    width: 100%;
    height: 2vmax;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: 0 var(--padding-2xs);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }

  .loginbutton {
    flex: 1;
    position: relative;
    height: 40px;
    min-width: 115px;
    cursor: pointer;
  }

  .loginbuttonDisabled {
    flex: 1;
    position: relative;
    height: 40px;
    min-width: 115px;
  }

  .image-30-parent,
  .lorem-ipsum-dolor-sit-amet-co-parent {
    align-self: stretch;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-30-parent {
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap-39xl);
    text-align: center;
    font-size: var(--font-size-mini);
    color: var(--color-white);
    font-family: var(--font-montserrat);
  }

  .lorem-ipsum-dolor-sit-amet-co-parent {
    flex-direction: column;
    gap: var(--gap-2xs);
  }

  .coursecard-1,
  .coursecard-1-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .coursecard-1 {
    flex: 1;
    border-radius: var(--br-xl);
    flex-direction: column;
    padding: var(--padding-base) var(--padding-mid);
    box-sizing: border-box;
  }

  .coursecard-1-parent {
    align-self: stretch;
    flex-direction: row;
    gap: var(--gap-31xl);
  }

  .p {
    margin: 0;
  }

  .kpi-wrapper-total,
  .kpi-wrapper-upcoming,
  .kpi-wrapper-progress
  {
    flex: 1;
    border-radius: var(--br-xl);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    height: 10vh;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    min-width: 150px;
  }

  .kpi-wrapper-total{
    background-color: #62e1b2;
    padding: 2vmax;
  }

  .kpi-wrapper-upcoming {
    background-color: #22c2e1;
    padding: 2vmax;
  }

  .kpi-wrapper-progress {
    background-color: #0073ec;
    padding: 2vmax;
  }

  .kpi-text-wrapper {
    flex: 1;
    position: relative;
    padding: 2vmax;
  }

  .kpi-text {
    margin-block-start: 0;
    margin-block-end: 3px;
  }

  .frame-div {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-31xl);
    text-align: center;
    font-size: var(--font-size-xl);
    font-family: var(--font-montserrat);
  }

  .user-container {
    align-self: stretch;
    justify-content: flex-start;
    gap: 2vmax;
    font-size: var(--font-size-base);
    margin-top: 1%;
    display: flex;
    flex-direction: column;
  }

  .frame-container,
  .frame-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .frame-container {
    align-self: stretch;
    justify-content: flex-start;
    gap: 1vmax;
    font-size: var(--font-size-base);
  }

  .frame-parent {
    flex: 1;
    // min-height: 100vh;
    // min-width: 600px;
    padding: 0 40px;
    box-sizing: border-box;
    justify-content: flex-start;
    gap: var(--gap-2xs);
    font-size: 32px;
  }

  .help-ai-customize {
    flex: 1;
    position: relative;
    font-weight: 500;
  }

  .help-ai-customize-your-experie-wrapper {
    align-self: stretch;
    background-color: var(--color-royalblue-100);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: 13px 14px;
    align-items: center;
    justify-content: center;
  }

  .frame-child {
    position: relative;
    width: 105.52px;
    height: 235.31px;
  }

  .group-parent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    min-height: 235px;
  }

  .image-32-icon {
    align-self: stretch;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 254.69px;
    flex-shrink: 0;
    object-fit: cover;
  }

  .frame-parent1 {
    flex: 1;
    height: 100%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 0 0 0 20px;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-31xl);
    min-width: 220px;
    max-width: 310px;
    text-align: center;
    font-size: 14px;
    color: var(--color-white);
    margin-bottom: 10%;
  }

  .frame-parent2 {
    flex: 1;
    height: 100%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 0 0 0 20px;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-31xl);
    min-width: 220px;
    max-width: 310px;
    text-align: center;
    font-size: 14px;
    color: var(--color-white);
    margin-bottom: 10%;
    align-items: center;
  }

  .john-sanders-parent {
    position: relative;
    padding: 5px;
    border-radius: var(--br-xl);
    border: 2px solid var(--color-royalblue);
    box-sizing: border-box;
    overflow: hidden;
  }

  .ron-sanders {
    font-size: var(--font-size-xl);
    font-weight: 600;
    padding-left: 0.5vmax;
  }

  .cranborne-avenue-tolworth-container {
    display: flex;
    align-items: center;
    padding: 0 0.5vmax;
  }

  .ron-sanders-parent {
    border: 2px solid var(--color-royalblue);
    box-sizing: border-box;
    margin: 0 0.25vmax !important;
  }

  .edit-profile-item,
  .ron-sanders-parent,
  .sarah-sanders-parent {
    padding: 5px;
    position: relative;
    border-radius: var(--br-xl);
    overflow: hidden;
  }

  .sarah-sanders-parent {
    border: 2px solid var(--color-royalblue);
    box-sizing: border-box;
  }

  .edit-profile-item {
    width: 243px;
    height: 243px;
    object-fit: cover;
  }

  .edit-profile-inner {
    border-top: 1px solid var(--color-royalblue);
    box-sizing: border-box;
    width: 100%;
    height: 1px;
  }

  .edit-profile {
    position: relative;
    background-color: var(--color-white);
    width: 100%;
    height: 780px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-xs);
    color: var(--color-black);
    font-family: var(--font-poppins);
  }

  .edit-icon {
    position: absolute;
    right: 10px;
    top: 5px;
    cursor: pointer;
  }

  .disabled-text {
    color: #888;
  }

  .tutor_accordian_summary {
    background: linear-gradient(180deg, #0146c7, #052d77);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 1vmax !important;
  }

  .reg_accordian_icon {
    color: #FFFF !important;
    font-size: 2vmax !important;
  }

  .reg_add_icon {
    color: #FFFF !important;
    font-size: 1.5vmax !important;
  }

  .tutor_accordian_header {
    margin-left: 1vmax !important;
    font-size: 1.5vmax !important;
    color: #FFFF !important;
  }

  .tutor_accordian_detail {
    background-color: #FFFF;
  }

  .course_cards_container {
    display: flex; 
    width: 100%;
  }

  .user-report {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: flex-start;
    gap: 1vmax;
    font-size: var(--font-size-base);
  }

  .user-report-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 2vmax;
    font-size: var(--font-size-base);
  }
  
  .course_cards {
    border: 1px solid #043897;
    border-radius: 1vmax !important;
    font-family: var(--font-poppins);

    .course_cards_header {
      background: linear-gradient(180deg, #0145C3 0%, #06307F 100%);
      overflow-y: auto;
      height: 7vh;
    }

    .course_cards_header_txt {
      font-weight: bold;
      font-size: 0.85vmax !important;
      padding: 0 0.5vmax;
      color: #FFFF;
    }

    .course_details_header {
      background-color: #FFFF;
      overflow-y: auto;
      height: 10vh;
    }

    .course_details_txt {
      font-size: 0.7vmax !important;
      color: #0B3D9A;
    }

    .course_details_icon {
      font-size: 1vmax !important;
      color: #0B3D9A;
    }

    .course_schedule_stack {
      background: linear-gradient(180deg, #0145C3 0%, #06307F 100%);
      height: 14vh;
    }

    .course_schedule_header {
      font-weight: bold;
      font-size: 0.7vmax !important;
      color: #FFFF;
      padding: 0.5vmax 0;
    }

    .course_schedule_txt {
      font-size: 0.7vmax !important;
      color: #FFFF;
    }

    .course_schedule_summary_stack {
      padding: 0.5vmax 2vmax;
      height: 8vh;
      overflow-y: auto;
    }

    .course_schedule_summary {
      font-size: 0.65vmax !important;
      color: #462929;
    }

    .course_contact_stack {
      padding: 0.5vmax 2vmax;
      height: 4vh;
      overflow-y: auto;
    }

    .course-schedule {
      height: 10vh !important;
      margin: 0 !important;
      width:100%;
      overflow-y: auto;
    }

    .course-schedule::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }
    .course-schedule::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: white ;
      -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }
  }

  .report_cards_container {
    display: flex; 
    width: 100%;
    flex-direction: column;
    gap: 1vmax;
    border: 1px solid #043897;
    background: linear-gradient(180deg, #0145C3 0%, #06307F 100%);
    border-radius: 1vmax !important;
    font-family: var(--font-poppins);
    height: 65vh;
    .report_cards_date {
      color: white;  
      height: 5vh;
      font-size: 0.75vmax;
      text-align: right;
      padding: 0.5vmax 1vmax 0 1vmax;
    }
  }

  .report_cards {
    border: 1px solid #043897;
    border-radius: 1vmax !important;
    font-family: var(--font-poppins);

    .report_cards_header {
      font-weight: 500;
      font-size: 1.25vmax !important;
    }
    
    .report_cards_txt {
      font-weight: 400;
      font-size: 1.15vmax !important;
      padding: 0 0.5vmax;
    }

    .report_cards_score {
      text-align: center;
    }

    .report_cards_score_total {
      font-weight: 400;
      font-size: 2.5vmax !important;
      padding: 0 0.5vmax;
      color: #043897;
    }

    .report_cards_module {
      height: 30vh;
      overflow-y: auto;
    }

    .report_cards_module_txt {
      font-weight: 400;
      font-size: 0.85vmax !important;
    }

    .report_cards_module_score {
      font-weight: 400;
      font-size: 1.35vmax !important;
      color: #043897;
    }

    .report_cards_module_percent {
      font-weight: 400;
      font-size: 1vmax !important;
      color: #043897;
    }
  }

  .result_grid_overview_stack {
    background-color: #E3F3FA;
  }

  .result_grid_correct_answer {
    color: #70D291;
  }

  .result_grid_wrong_answer {
    color: #E32636;
  }

  .result_grid_header {
    background-color: #505050;
    color:#FFFF;
  }

  .review_result_icon {
    height: 2vmax;
    width: 2vmax;
  }

  .review_result_check_div {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5vmax;
  }
  
}

.appbar {
  background-color: #FFF !important;
}

.toolbar {
  display: 'flex';
  justify-content: 'space-between';
  align-items: 'center';
}

.logo_img {
  width: 3vmax;
  margin-top: 0.25vmax;
  cursor: pointer;
}


.menu_item {
  text-transform: uppercase;
  color: #0146C7 !important;
  font-family: var(--font-poppins) !important;
  font-weight: 600 !important;
  font-style: normal;
  font-size: 1.30vmax !important;
  text-decoration: none !important;
  transition: scroll-behavior 0.5s ease-in-out;
}

.popup_menu_item {
  font-family: var(--font-poppins) !important;
  font-size: 1vmax !important;
  font-weight: 600 !important;
  font-style: normal;
  font-size: 1vmax !important;
  color: #FFF !important;
  text-transform: uppercase;
}
.popup_menu_item:hover {
  color: #0146C7 !important;
  background-color: #FFF !important;
}

.phone_link {
  text-decoration: none !important;
  color: #FFF !important;
  // background-color: #0073ec;
}

.phone_icon {
  margin-right: 0 !important;
}

.background_color {
  background: #0146C7 !important;
}

.icon_btn {
  margin: 5px !important;
}

.icon {
  font-size: 1.5vmax !important;
}

.nav_typography {
  margin: 5px !important;
  margin-left: -12px !important;
  padding: 1px 20px !important;
  font-family: var(--font-poppins) !important;
  font-weight: 400 !important;
  font-size: 0.95vmax !important;
}

.avator_icon {
  font-size: 1.25vmax !important;
  background: #0146C7 !important;
  height: 2vmax !important;
  width: 2vmax !important;
}


.dashboardLink ,.reportsLink {
  text-decoration: none;
}

.frame-group {
  font-family: var(--font-poppins);
  margin-left: 5%;
}

.user-header-group {
  font-family: var(--font-poppins);
  margin-top: 2%;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1vmax;
}

.hello-john-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: black;
  width: 100%
}

.hello-john-txt-container {
  font-weight: bold;
}

.hello-john-txt-container span {
  font-weight: 500; /* semi-bold */
}

.good-morning {
  font-weight: 300; /* light */
  white-space: nowrap; /* Prevent greeting from wrapping */
}
.ex-act-rpt-details {  
  margin: 20px;
  font-size: 1.2vmax !important;
}

.ex-act-rpt-org-name {
  font-size: 1.2em;
  font-weight: bold;
}
.ex-act-rpt-root {
  justify-content: center !important;
}
.ex-act-rpt-std-name {
  text-align: center;
  font-family: var(--font-poppins);
  font-weight: 400;
  font-size: 1.5vmax !important;
}
.ex-act-rpt-home {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center !important;
}
.ex-act-rpt-h-style{
  margin-bottom: 0px !important;
}
.ex-act-rpt-p-style{
 margin-top: 0px !important;
}
.sub-par-div-style{
  display: flex;
  flex-direction: row;
  // margin: 0 auto !important;
  // justify-content: space-evenly;
}

.my-sub-par-div-style{
  display: flex;
  flex-direction: row;
  overflow-x: auto;  /* Enable horizontal scroll */
  overflow-y: hidden; 
  width: 100%;
  padding: 2vh;      /* Adjust padding if needed */
  height: 60vh;      /* Set height */
  justify-content: space-evenly;
}

.sub-most-pop-style{
  width: 50vh;
  height: 5vh;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  text-align: center;
  align-items: center;
  background-color: blue;
  font-family: var(--font-poppins);
  font-weight: bold;
  padding-top: 10px;
  color: white;
  font-size: 1.25vmax;
}
.sub-non-pop-style{
  width: 50vh;
  height: 5vh;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;  
}
.sub-card-style{
  height: 55vh;
  border-bottom-right-radius: 25px !important;
  border-bottom-left-radius: 25px !important; 
  background-color: transparent !important; 
}
.sub-card-rad-style{
 border-radius: 25px !important;
 background-color: transparent !important; 
}
.sub-main-div-style{
  height: 55vh;
  font-family: var(--font-poppins);
  color: #F2F2F2;
  font-size: 1.75vmax !important;   
  text-align: center;
  background-color: #788AAB;
  opacity: 0.8;
}
.sub-main-div-rad-style{
  width: 50vh;
  height: 55vh;
  font-family: var(--font-poppins);
  color: #F2F2F2;
  font-size: 1.75vmax !important;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px; 
  text-align: center;
  background-color: #788AAB;
  opacity: 0.8;
}
.sub-period-style {
  height: 11vh;  
  padding: 2.5vw;
  font-size: 1.8vmax !important;
  display: flex;  /* Uncomment if you want flexbox layout */
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.My_sub-period-style{
  height: 8vh;  
  padding: 2vh;
  font-size: 1.8vmax !important;
}


.My-sub-monthly-style{
  height: 7.5vh;
  padding-top: 2.5vh;
  font-size: 2vmax !important;
}

.sub-monthly-style{
  height: 7.5vh;
  padding-top: 2.5vh;
  font-size: 2vmax !important;
  margin-top: 1vh;
}
.sub-desc-style{
  height: 24vh;
  text-align: left;
  padding: 5vh;
  font-size: 1.25vmax !important;
}
.sub-my-desc-style{
  height: 14vh;
  text-align: left;
  padding-top: 1vh;       /* Top padding set to 5vh */
  padding-right: 2vh;     /* Right padding set to 2vh */
  padding-bottom: 11vh;    /* Bottom padding set to 2vh */
  padding-left: 2vh;      /* Left padding set to 2vh */
  font-size: 1.25vmax !important;
}
.sub-div-footer-style{
  bottom: 0;
  font-size: 1.5vmax;
  font-family: var(--font-poppins);
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.25vmax;
}
.wait_stack_dashboard {
  position: absolute;
  background-color: lightgray !important;
  height: 100%;
  width: 100%;
  z-index: 10;
}
// @import url("https://fonts.googleapis.com/css2?family=Font+Awesome+6+Duotone:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

@import url(./assets/font-awesome/scss/font-awesome.scss);

body {
  margin: 0;
  line-height: normal;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}
:root {
  /* fonts */
  --font-poppins: Poppins;
  --font-montserrat: Montserrat;
  --font-inherit: inherit;
  --font-font-awesome-6-duotone: "Font Awesome 6 Duotone";

  /* font sizes */
  --font-size-xl: 1.25vmax;
  --font-size-mini: 15px;
  --font-size-base: 0.85vmax;
  --font-size-19xl: 38px;
  --font-size-3xs: 10px;
  --font-size-lg: 18px;
  --font-size-btn: 0.85vmax; 


  /* Colors */
  --color-white: #fff;
  --color-royalblue-100: #0146c7;
  --color-royalblue-200: #0046c3;
  --color-black: #000;
  --color-royalblue: #0146C7;

  /* Gaps */
  --gap-31xl: 50px;
  --gap-2xs: 11px;
  --gap-39xl: 58px;
  --gap-3xs: 10px;


  /* Paddings */
  --padding-base: 16px;
  --padding-mid: 17px;
  --padding-2xs: 11px;

  /* Border radiuses */
  --br-xl: 20px;
  --br-11xl: 30px;
  --br-6xl: 25px;

  
}


// body {
//   margin: 0;
//   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }
